.badge {
  color: #0F121B;
  background-color: white;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border-radius: 0.5rem;
--smooth-corners: 5;
  -webkit-mask-image: paint(smooth-corners);
  mask-image: paint(smooth-corners);
}


.step {
  background-color: $r330;
  color: $r4;
  @extend .small;
  padding: 4px 8px;
  border-radius: 8px;
  font-weight: 500;
}


.bg-light {
  background-color: rgba(255, 255, 255, .7) !important;
}

#footprint-app {
  .btn-primary {
    background-color: $o1;
  }

  &.lang-se {
    @media screen and (max-width: 420px) {
      .max-w-150 {
        max-width: 150px;
      }
    }
    @media screen and (max-width: 400px) {
      .special-size-sm {
        font-size: 42px;
      }
    }

  }

  .graph-bars {
    > div {
      border-radius: 6px;
      // max-width: 64px;
    }
  }

  .modal-dialog {
    @extend .modal-fullscreen-sm-down;
  }
}

#summary-block {
  background: #EFF5F0;
  color: $f490;

  .btn-primary {
    background-color: $f1;
  }

  .bg-dark {
    background-color: #E6EFE8 !important;
  }
}

#lifestyle-block {
  background-color: #F7E8EA;
  color: $r490;

  // .btn-primary {
  //   background-color: $r1;
  // }

  .block__content {
    background: #FBF3F5;
  }

  .bg-dark {
    background-color: #FBF3F5 !important;
  }

  .bg-darker {
    background-color: #F9E7EB;
  }
}

#investments-block {
  background-color: #F4F6FB;

  .btn-primary {
    background-color: $o1;
  }

  .bg-dark {
    // background-color: #F4EDF7!important;
  }
}

#business-block {
  background-color: #F8F4FA;
  color: $v490;

  // .btn-primary {
  //   background-color: $v1;
  // }

  .bg-dark {
    background-color: #F4EDF7 !important;
  }
}

#footprint-app {
  color: $o490;

  .header {
    pointer-events: none;
  }

  .form-select {
    border-radius: 100px;
    padding: 20px 72px 20px 24px;
  }

  .form-control {
    border-radius: 100px;
    padding: 20px 24px;
  }

  .top-bg {
    &.top-bg--alt {
      @include media-breakpoint-down(md) {
        position: absolute;

        img {
          height: 100%;
        }
      }

    }

  }

  .block--hero {
    margin-top: -100vh;

    @include media-breakpoint-down(md) {
      margin-top: 0;
    }
  }

  .framework-visual {
    margin: 0 auto;
    width: 100%;
    max-width: 400px;
  }


  #swiper-group {
    z-index: 1055 !important;
    overflow-x: hidden !important;
    background: $s1;
    color: $o490;

    .footprint-value {
      // font-size: 32px;

      .small {
        font-size: 16px;
      }
    }

    .block--header {
      color: $s1;
    }

    .top-bg {
      &.top-bg--alt {
        img {
          opacity: 1;
        }
      }
    }

    .swiper-wrapper {
      height: 100vh;

      .swiper-slide {
        overflow-y: auto;
      }
    }

    .block--header {
      // z-index: 2;
      // min-height: 400px;
      // height: 45vh;
      // margin: 120px 0 0;
      // color: white;
      background-size: cover;
      height: 70vh;
      background-position: top;
      display: none;

      // display: none;

      .block--header__media {
        max-width: 100%;
        width: 100%;
        padding-top: 144px;
      }
    }

    .block__content {
      padding-top: 440px;
      padding-bottom: 0;
      background-position: top;
      // background-size: contain;
      background-repeat: no-repeat;

      .top-img {
        top: 96px;
      }

      @include media-breakpoint-down(lg) {
        padding-top: 384px;

        .top-img {
          top: 16px;
        }
      }
    }

    .card {
      border-radius: 24px;


      .card__inner {
        border-radius: 24px;
        background: #F5F8FC;
      }
    }


    .block__footer {
      // background: $s150;
    }


    .accordion-item {
      border-radius: 24px;
      //background: transparent;
      //border: 2px solid transparent !important;
      position: relative;

      //&.active {
      //  border: 2px solid !important;
      //}
    }

    .accordion-button {
      padding: 0;
      color: inherit;

      //&:after {
      //  display: none;
      //}
    }

  }

  .headroom-wrapper {
    pointer-events: none;
  }

  .headroom {
    transition-timing-function: cubic-bezier(.16, 1, .3, 1);
    transition-duration: .6s;
    transition-property: background-color, top, left, width, opacity, transform;
    pointer-events: all;

    &.headroom--unfixed {
      transform: translateY(0);
    }

    &.headroom--pinned {
      transform: translateY(-100%);
    }

    &.headroom--unpinned {
      transform: translateY(-100%);
      opacity: 0;
      pointer-events: none;
    }

  }

  .swiper-navigation {
    background: transparent;
    border-radius: 0;

    .swiper-navigation__nav {
      padding: 16px 72px 16px 8px;
      overflow-x: auto;

      /* WebKit-based browsers */
      &::-webkit-scrollbar {
        display: none;
      }

      /* Firefox */
      /* Note: This only works on Firefox Nightly, as of September 2021 */
      &::-moz-scrollbar {
        display: none;
      }

      &:after {
        content: "";
        width: 64px;
        pointer-events: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(90deg, transparent, $s1);
      }

      @include medium {
        padding: 0;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        &:after {
          display: none;
        }
      }

    }

    .swiper-nav-item {
      font-weight: 500;
      cursor: pointer;
      transition: .15s ease;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      padding: 0 16px;

      @include medium {
        padding: 0 24px;
      }

      &.active {
        background-color: white;
      }

      &:hover {
        opacity: 1;
        @extend .bg-light;
      }
    }
  }

  .transition-rect {

    &.active {
      fill: #F692A3;
      stroke: #2F3A71;
      stroke-width: 2px;
      stroke-opacity: 1;
    }
  }

  .sustainable-rect {
    &.active {
      fill: #7B9B7E;
      stroke: #2F3A71;
      stroke-width: 2px;
      stroke-opacity: 1;
    }
  }

  .neutral-rect {
    &.active {
      fill: #385795;
      stroke: #2F3A71;
      stroke-width: 2px;
      stroke-opacity: 1;
    }
  }

  .significant-rect {
    &.active {
      fill: #96355D;
      stroke: #2F3A71;
      stroke-width: 2px;
      stroke-opacity: 1;
    }
  }

  .card .card-header {
    border-bottom: 1px solid rgba(47, 58, 113, 0.2);
  }

  .accordion-button {
    @extend .p-4;
  }

  .accordion-button::after {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_1484_20801' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect x='24' width='24' height='24' transform='rotate(90 24 0)' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_1484_20801)'%3E%3Cpath d='M3.00592 7.19186C3.17557 7.02478 3.38005 6.93953 3.61937 6.93611C3.85869 6.93268 4.06531 7.01793 4.23922 7.19186L12.0271 14.9797L19.8232 7.17711C19.9869 7.01345 20.1878 6.9299 20.4258 6.92649C20.6638 6.92307 20.8709 7.01153 21.0469 7.19186C21.2209 7.35896 21.3106 7.56387 21.3161 7.80659C21.3217 8.04932 21.2375 8.25765 21.0636 8.43159L12.5751 16.9149C12.4884 16.9974 12.401 17.0562 12.313 17.0912C12.2249 17.1262 12.1296 17.1438 12.0271 17.1438C11.9202 17.1438 11.8228 17.1262 11.7348 17.0912C11.6467 17.0562 11.5615 16.9974 11.479 16.9149L2.98092 8.41684C2.81726 8.25317 2.73542 8.05318 2.73542 7.81686C2.73542 7.58053 2.82559 7.37219 3.00592 7.19186Z' fill='%232F3A71'/%3E%3C/g%3E%3C/svg%3E%0A");
  }

  .accordion-button:not(.collapsed)::after {
    transform: rotate(180deg);
  }

  .accordion-value {
    opacity: 0;
    transition: 0.1s ease-in-out;
    // position: absolute;
    left: 0;
    bottom: 0;
    // padding-bottom: 8px;
  }

  .accordion-button:not(.collapsed), .accordion-button.collapsed {
    .accordion-value {
      opacity: 1;
    }
  }

  .extra-small {
    font-size: 12px;
  }
}

//
//#footprint-app.result-page {
//  #swiper-group {
//    .swiper-wrapper {
//      height: auto;
//    }
//  }
//}
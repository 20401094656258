.footer {
  .block--cta {
    .block__content {
      padding-top: 120px;
      padding-bottom: 40px;

      @include large {
        padding-top: 200px;
        padding-bottom: 64px;
      }
    }
  }
}

#footprint-app {
  footer {

    li a {
      font-size: 15px;
    }
  }
}

.block-slider.block {
  // background:$o250 ;
  .slider {

    .swiper {
      overflow: visible;
    }

    .swiper-wrapper {
      overflow: visible;

      &:hover {
        .swiper-slide {
          opacity: .8 !important;
        }
      }

      .swiper-slide {
        width: auto;
        transition: transform 0.15s ease-in, opacity .15s ease-in;

        &:hover {
          // transition: transform .25s ease-in;
          opacity: 1 !important;
          z-index: 1;
          transform: scale(1.01) !important;
        }

        &:active, &:focus {
          transform: scale(.98) !important;
        }
      }
    }


    h2:hover, .center-number:hover {
      cursor: grabbing;
    }

    .center-number:active {
      cursor: grabbing;
    }
  }

  .swiper-wrapper {
    & > .slide:first-of-type {
      // margin-left: 8vw;
    }

    .slide {
      &:hover {
        cursor: pointer;
      }

      .svg-holder {
        &.active {
          // filter: drop-shadow(2px 4px 6px black)
        }

      }

      svg:hover {
        transform: perspective(500px);
      }
    }

    &:hover {
      cursor: grab;
    }

    &:active {
      cursor: grabbing;
    }

  }

}

.modal .icon svg {
  width: 100%;
  height: auto;
}

//.swiper-wrapper {
//  height: auto !important;
//}
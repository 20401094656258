.sticky-container {
  min-height: 400vh;

  .sticky-element {
    position: sticky;
    top: 0;
    height: auto;
    min-height: 100vh;

    .block__content {
      min-height: 100vh;
      height: auto;

      @include large {
        min-height: 120vh;
      }
    }
  }
}


body {
  position: relative;
  .navbar-brand {
    svg {
      path {
        fill: $s1;
      }
    }
  }

  .block--hero {
    color: $s1;
  }

  .block--bg {
    color: $s1;
  }

  .block--sticky {
    position: sticky;
    backface-visibility: hidden;
    top: 0;
    padding-bottom: 150vh;
    margin-bottom: -200vh;

    .block__content {
      height: 100vh;
    }
  }

  .main {
    z-index: 1;
    height: 0;
    overflow: hidden;
  }

  .main, footer {
    background-color: transparent;
    position: relative;

    .block:not(.ignore-theme) {
      transition: color .45s ease, background-color .45s ease, fill 45s ease;
      color: var(--dark);
      background-color: var(--light);

      &.block--rounded-top {
        &:before {
          background-color: var(--light);
        }
      }

      &.s1 {
        background-color: var(--base);
      }
    }

    .block--invert:not(.ignore-theme) {
      color: var(--light);
      background-color: var(--dark);

      .btn-primary {
        color: var(--dark);
        background-color: var(--medium);
      }
    }

    .grid__item {
      transition: color .4s ease, background-color .4s ease, scale .15s cubic-bezier(0.7, 0, 0.3, 1);

      .grid__item__inner, .label {
        transition: color .4s ease, background-color .4s ease;
      }

      @include large {
        &:hover {
          scale: 1.05;
        }
      }

      &.no-image {
        &:not(.active) {
          &:hover {
            background-color: var(--medium);
          }
        }
      }
    }

    .grid__item {
      &.active {

        @include large {
          &.no-image {
            background-color: var(--medium);
            color: var(--bs-white);
          }
        }

        &:not(.no-image) {

          .grid__item__inner {

            @include large {
              color: var(--bs-white);
            }
          }
        }
      }
    }
  }
}

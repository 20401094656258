.takeAction {
  will-change: auto !important;
  z-index: 1;
}

.block.block--rolling-header {
  min-height: 50vh;

  @include large {
    min-height: 75vh;
  }
}


.block--takeaction {
  margin-bottom: 0 !important;

  .progress {
    // background-color: $s2;
    .progress-bar {
      background:$r1;
      // background:linear-gradient(270deg, #B00000 -53.33%, #FFE071 100%)!important;

      transition: width ease-in-out .2s;
    }
  }

  .block__base {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: none;
  }

  // .floating-images {
  //
  //   .floating-images__item {
  //     flex: 1 1 0px;
  //
  //     img {
  //       box-shadow: 0px 2px 56px rgba(0, 0, 0, .1);
  //       max-width: 100%;
  //       border: 0.1875rem solid #fff;
  //       border-radius: 1.5rem;
  //
  //       // @include large-down {
  //       //   aspect-ratio: 2 / 3;
  //       //   object-fit: cover;
  //       // }
  //
  //       @include large {
  //         border: 0.375rem solid white;
  //         border-radius: 2.5rem;
  //       }
  //     }
  //   }
  //
  //   .inner {
  //     position: relative;
  //
  //     @include large {
  //       transform: translateX(10%) translateY(90%);
  //     }
  //
  //     .label {
  //       @extend .serifBoldItalic;
  //       // @extend .gradient-text;
  //       padding: 8px 16px;
  //       display: inline-block;
  //       font-size: 16px;
  //
  //       @include large {
  //         font-size: 18px;
  //       }
  //     }
  //
  //     @include large-down {
  //       transform: none !important;
  //     }
  //   }
  //
  //   @include large {
  //     > div {
  //       &:first-child {
  //         .inner {
  //           transform: translateX(20%) translateY(-10%);
  //         }
  //       }
  //
  //       &:last-child {
  //         .inner {
  //           transform: translateX(-20%) translateY(30%);
  //         }
  //       }
  //     }
  //   }
  // }

  // .preheader {
  //   @extend .serifBoldItalic;
  //   // @extend .gradient-text;
  //   color: var(--accent);
  //
  //   @include large {
  //     font-size: 24px;
  //   }
  // }

  // h2 {
  //   @extend .serifRegular;
  //
  //   i {
  //     @extend .serifItalic;
  //     font-weight: 600;
  //   }
  // }

  // .block__base {
  //   // position: absolute;
  //   // background-color: $r250;
  //   // top: 0;
  //   // left: 0;
  //   // right: 0;
  //   // bottom: 0;
  //   // min-height: 400px;
  //
  //   circle {
  //     fill: $r250;
  //   }
  // }

  &:first-of-type {
    padding-bottom: 0;
  }

  // &:not(:first-of-type) {
  //   // padding-bottom: 100vh!important;
  //   // top: -24px!important;
  // }

  // .paris-target {
  //   left: 23%;
  //   bottom: 18%;
  // }
  //
  // .arrow {
  //   left: -3%;
  //   top: 17%;
  // }
  //
  // .tco2 {
  //   top: 0;
  //   right: 0;
  //   font-size: 12px;
  // }
  //
  // .element-8.active {
  //   .change-color {
  //     // fill: #282947 !important;
  //   }
  // }

  // svg {
  //   max-width: 100%
  // }
  //
  // .middle-element {
  //   margin: -35px 0;
  // }
  //
  // #switcher-button {
  //   cursor: pointer;
  // }
  //
  // #switcher {
  //   cursor: pointer
  // }
  //
  // .circle {
  //   display: inline-flex;
  //   align-items: center;
  //   justify-content: center;
  //   border-radius: 50%;
  //   flex-direction: column;
  //   position: absolute;
  //
  // }
}


// .sdg-card {
//   position: relative;
//   display: inline-block;
//   border-radius: 12px;
//
//   @supports (mask-image: paint(smooth-corners)) {
//     border-radius: 0;
//     mask-image: paint(smooth-corners);
//     --smooth-corners: 4;
//   }
//
//   img {
//     max-width: 80px;
//     max-height: 80px;
//     width: 100%;
//     position: relative;
//     padding: 8px;
//     overflow: hidden;
//     -moz-user-select: none;
//     -khtml-user-select: none;
//     -webkit-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
//   }
// }



.top-bg {
  position: fixed;
  z-index: 1;
  // top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 115%;
  width: 100%;
  background-color: black;

  img {
    object-fit: cover;
    height: 110%;
    width: 110%;
  }

  &.top-bg--alt {
    width: 100%;
    position: sticky;
    top: 0;
    height: auto;
    min-height: 100vh;

    img {
      height: 100vh;
      width: 100%;
      opacity: .8;
    }
  }
}


input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: red !important;
    color: blue !important;
}

.block.block--hero {
  min-height: 100vh;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;

  .block__media {
    overflow: hidden;
    position: absolute;
    z-index: 1;
  }

  // button.submit {

  //   svg {
  //     @include large {
  //       width: 40px!important;
  //       height: 40px!important;
  //     }
  //   }
  // }

  .navbar-brand {
    top: 16px;
    position: absolute;
    right: 24px;
    z-index: 100000;
    transform: rotate(-90deg);
    transition: opacity .15s ease-in-out;
    transform-origin: right;

    display: none;

    @include large {
      top: 80px;
      right: 32px;
    }

    svg {
      path {
        fill: white;
      }
    }

    &:hover {
      opacity: .5;
    }
  }

  .block__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include large-down {
      opacity: 1!important;
    }

    @include large {
      padding-top: 160px;
    }
  }

  .block__media--fade {
    &:after {
      position: absolute;
      opacity: 1;
      content: "";
      width: 100%;
      bottom: 0;
      top: auto;
      height: 50vh;
      z-index: 2;
      float: left;
      position: absolute;
      background: linear-gradient(to top, rgba(0,0,0,.2) 0%, rgba(0, 0, 0, 0.738) 19%, rgba(0, 0, 0, 0.541) 34%, rgba(0, 0, 0, 0.382) 47%, rgba(0, 0, 0, 0.278) 56.5%, rgba(0, 0, 0, 0.194) 65%, rgba(0, 0, 0, 0.126) 73%, rgba(0, 0, 0, 0.075) 80.2%, rgba(0, 0, 0, 0.042) 86.1%, rgba(0, 0, 0, 0.021) 91%, rgba(0, 0, 0, 0.008) 95.2%, rgba(0, 0, 0, 0.002) 98.2%, transparent 100%);
    }
  }

  .block__media {
    background-color: transparent;
    background-position: 50% 30%;
    background-size: cover;
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    background-color: #020B07;

    img {
      height: 110%;
      left: 50%;
      object-fit: cover;
      position: absolute;
      top: 50%;
      width: 120%;
      object-position: bottom;

      //new code
      opacity: .9;
      width: 110%;
      //new code
    }
  }

  form {

    label {
      font-size: 14px;
      margin-top: 8px;

      @include large {
        font-size: 16px;
        margin-top: 0;
      }
    }

    &.invalid {
      label {
        @extend .animate__animated;
        @extend .animate__headShake;
      }

      input {
        border-color: rgba($s1, 0.8);
      }

    }
  }

  // .form-wrapper {
  //   position: absolute!important;
  //   bottom: 0;
  //   left: 50%;
  //   transform: translateX(-50%)!important;
  // }

  .form-input--large {
    appearance: none;
    border: none;
    background-color: rgba(0, 0, 0, 0.35);
    color: currentColor;
    font-size: 24px;
    padding: 16px 80px 16px 24px;
    line-height: 1;
    margin: 2px 0;
    width: 100%;
    @extend .serifBold;
    border-radius: 80px;
    transition: all .25s ease-in-out;
    border: 2px solid transparent;

    &:focus {
      background-color: rgba(0, 0, 0, 0.5);
      border-color: rgba($s1, 0.8);
    }
    &::placeholder {
      color: #5b5d5c;
    }
    @include small {
      font-size: 32px;
    }

    // @include large {
    //   padding: 16px 80px 16px 32px;
    //   font-size: 48px;
    // }

    + #suffix {
      visibility: hidden;
      opacity: 0;

      transition: opacity .15s ease-in-out;
    }

    &:valid {
      // border-color: hsl(120, 76%, 50%);

      + #suffix {
        visibility: visible;
        opacity: 1;
      }
    }

    &:focus-visible {
      outline: 0;
    }
  }

  #suffix {
    left: 0;
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    padding-left: 40px;
  }

  .submit {
    height: 72px;
    width: 72px;
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    transition: all .25s ease-in-out;
    background: $s1;

    &:active {
      transform: scale(.95);
      transform-origin: center;
    }

    @include large {
      height: 64px;
      width: 64px;
    }

    svg {
      position: relative;
      z-index: 1;
      width: 24px;
      height: 24px;
      rotate: -90deg;

      // @include large {
      //   width: 40px;
      //   height: 40px;
      // }

      path {
        fill: #36362f;
      }
    }

    &:hover {
      background: $s2;

      svg {
        position: relative;
        z-index: 1;
      }
    }
  }
}

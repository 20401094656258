.btn--cart {
  padding: 0 8px;
  height: 56px;
  width: 56px;
  color: #fff;
  position: relative;
  border-radius: 24px;
  background-color: white;
  transition: all ease-in-out .15s;
  z-index: 1;
  margin-right: 16px;
  margin-top: 12px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);

  @include media-breakpoint-up(md) {
    margin-right: 48px;
    margin-top: 24px;
  }

  &:focus {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
  }

  svg {
    width: 32px;
    height: 32px;
  }

  .btn--cart__no {
    font-size: 12px;
    background-color: $o270 !important;
    color: $o4 !important;
    height: 20px;
    min-width: 20px;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -4px;
    right: -4px;
    font-weight: 600;
  }
}

// .cart--icon {
//   padding: 0 8px;
//   height: 56px;
//   width: 56px;
//   color: #fff;
//   position: relative;
//   border-radius: 100px;
//   background-color: white;

//   svg path {
//     fill: $o4!important;
//   }

//   span {
//     opacity: 1;
//   }

// &.active {
//   padding: 0 18px;
//   background: linear-gradient(92.35deg, #182361 6.17%, #45A2FF 103.37%);

//   svg path {
//     fill: #fff;
//   }
// }

// &:hover {
//   // transform: translateY(-0.1rem);
//   background-color: $s1;
// }

// &.active {
//   &:hover {
//     background: linear-gradient(92.35deg, #182361 20%, #182361 103.37%);
//   }
// }
// }


.offcanvas--cart {

  .btn-task + .cat-title {
    margin-top: 32px;
  }

  @include media-breakpoint-up(sm) {
    margin: 32px;
    border-radius: 40px;
    padding: 8px 12px 0;
  }
}
#swiper-cart-wrapper {
  z-index: 1057;
}
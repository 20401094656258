

.beta-bar {
  background-color: #0F121B!important;
  color: $s1!important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  border-radius: 0 0 12px 12px;
  transform: translate(0,-100%);
  // display: none!important;

  transition-timing-function: cubic-bezier(.16,1,.3,1);
  transition-duration: 1s;
  transition-property: opacity, transform;
  transition-delay: 1s;

  @include small {
    display: none;
  }

  .tool-label {
    display: flex;
    .tool-label__title {
      border: none;
      margin: 0;
      padding: 0;
      font-size: 14px;
    }

    .badge {
      margin-top: 0;
    }
  }


  //NEW
  &.beta-bar--alt {
    background-color: white!important;
    color: #333!important;
    left: 50%;
    right: auto;
    transform: translate(-50%,-100%);
    border-radius: 0 0 12px 12px;
    box-shadow: -20px -20px 27px rgba(0, 0, 0, 0.25);
  }

  .loaded & {
    transform: translate(0,0);
  }

  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    @extend .gap-1;
  }

  @include small-down {
    + .mainHeader {
      top: 40px;
    }
  }



  p {
    letter-spacing: 0;
    font-size: 12px;
    font-weight: 500;
    display: inline-block;
  }
}

.alert-icon {

  svg {
    width: 28px!important;
    height: 28px!important;

    path {
      stroke: currentColor!important;
    }
  }
}

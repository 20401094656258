// .floating-img {
//   border-radius: 2.5rem;
//   border: 4px solid $s1;
//   overflow: hidden;
//
//   img {
//     height: 100%;
//     width: 100%;
//     display: block;
//     object-fit: cover;
//   }
// }

section.block--impact {
  justify-content: flex-start;
  // background-color: #0F121B !important;

  .large.currency {
    @include small-down {
      &:after {
        font-size: .3em;
      }
    }
  }

  .pos-value, .neg-value {
    height: 32px !important;
    width: 32px !important;
    min-width: 32px;

    @include large {
      height: 64px !important;
      width: 64px !important;
      min-width: 64px;
    }
  }

  .block__content__footer {
    position: absolute;
    left: auto;
    bottom: 64px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 64px);
    max-width: 600px;
    padding-bottom: 0;
  }

  .fine-print {
    opacity: .5;
    padding: 16px 0 24px;
    line-height: 1.4;
    font-size: 11px;
    text-align: center;
    margin: 0 auto 0;

    @include large {
      font-size: 13px;
    }
  }

  .subheader {
    position: absolute;
    transform: translate(-50%, 0%);
    left: 50%;
    font-size: 14px;
    line-height: 1.5;
    margin: 24px 0 0;
    width: calc(100% - 64px);

    @include large {
      font-size: 16px;
    }


    @include large-down {
      max-width: 600px;
    }

    strong {
      // display: block;
      color: #FFD94D;
      // @extend .serifBoldItalic;
      // font-size: 1.5em;
    }
  }

  .impact-label {
    position: absolute;
    transform: translate(-50%, -100%);
    left: 50%;
    margin-top: -24px;
    width: calc(100% - 64px);
    font-weight: 500;
    letter-spacing: -0.01em;

    @include large-down {
      max-width: 600px;
    }

    strong {
      border-bottom: 2px solid;
    }

    // @include large {
    //   font-size: 22px;
    // }
  }

  .block__media {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .block__content {
    padding: 96px 0 144px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    text-align: center;
    background-size: cover;
    background-position: top;
    padding: 0;
    min-height: 150vh;

    .block__media {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-size: cover;
      background-position: top;
      z-index: -1;
    }

    .container {
      height: 100vh;
      position: relative;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      justify-content: center;
    }

    &.one {
      position: absolute;
      width: 100%;

      svg path:not(.no-fill) {
        fill: $s4;
        fill: currentColor;
      }

      h2 {
        // color: $s4;
      }
    }

    &.two {
      color: $s1;
      will-change: clip-path;
      position: relative;
      overflow: hidden;
      clip-path: inset(100% 0px 0px);

      backdrop-filter: blur(16px);
      background-color: rgba(26, 26, 26, 0.5);

      svg {
        path:not(.no-fill) {
          fill: $s1;
        }
      }
    }
  }
}

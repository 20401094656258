.list {
  .list__item {
    padding-top: 16px;
    position: relative;

    &:before {
      position: absolute;
      content: "";
      display: block;
      border-top: 1px dashed;
      width: 100%;
      top: 0;
      opacity: .3;
    }
  }
}

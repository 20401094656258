.block {
  position: relative;
  will-change: transform; //fixes flickering in chrome
  // we need to have this when using gsap, if something else breaks or will not work do not remove this before telling me

  // @include large {
  //   min-height: 100vh;
  // }

  .block__media {
    background-color: transparent;
    background-position: 50% 30%;
    background-size: cover;
    background-repeat: no-repeat;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  .block__content {
    padding: 80px 0;
    z-index: 1;
    position: relative;

    @include large {
      padding: 120px 0;
    }
  }

  .block--business {
    .card {
      background: #EAE2D7;
    }
  }
}

.block--rounded-top {

  &:before {
    transition: background-color .45s ease;
    content: "";
    position: absolute;
    top: -2.5rem;
    left: 0;
    right: 0;
    height: 2.6rem;
    z-index: 1;
    border-top-left-radius: 2.5rem;
    border-top-right-radius: 2.5rem;
    background-color: var(--light);

    .s1 & {
      background-color: var(--base);
    }
  }
}

.block--rounded-bottom {
  padding-bottom: 2.5rem;

  &:after {
    transition: background-color .45s ease;
    content: "";
    position: absolute;
    bottom: -2.5rem;
    left: 0;
    right: 0;
    height: 2.6rem;
    background-color: var(--light);
    z-index: 1;
    border-bottom-left-radius: 2.5rem;
    border-bottom-right-radius: 2.5rem;
  }
}

.block--cta {
  margin-top: -2.5rem;
  padding-top: 2.5rem;
}

.block {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.block__heading {
  font-size: 120px;
  line-height: 1;
  margin-bottom: 40px;

  &.block__heading--large {
    font-size: 80px;
    white-space: nowrap;

    span {
      display: inline-block;
      animation: crawling-line 10s linear infinite;
    }

    @include small {
      font-size: 96px;
    }

    @include medium {
      font-size: 120px;
    }

    @include large {
      font-size: min(max(40px, 15vw), 240px);
    }
  }

  &.block__heading--small {
    font-size: 44px;

    @include medium {
      font-size: 54px;
    }

    @include large {
      font-size: 64px;
    }
  }
}

.block__media--fade {
  &:after {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: calc(100% - 200px);
    bottom: 0;
    left: 0px;
    right: 0px;
    background: linear-gradient(transparent 0%, rgb(15, 15, 15) 100%);
  }
}

.block__heading {
  .lead {
    width: 0;
    min-width: 100%;
  }
}

.company-logo {
  height: 32px;
  position: absolute;
  margin-top: -17px;
  rotate: -4deg;
  display: block;

  @include large {
    rotate: -8deg;
    transform: translate(-40%, -100%);
  }
}

.progress-list {
  .progress-list__item {
    @extend .px-lg-6;
    @extend .px-4;

    &.current {
      @extend .px-lg-6;
      @extend .px-4;
      @extend .py-4;
      @extend .py-lg-5;
      @extend .rounded;
      @extend .bg-dark;
    }
  }
}

//.block--business {
.progress {
  background: rgba(0, 0, 0, 0.05);

  #business-block & {
    background: #EDE3F2;
  }

  &.progress-bar-vertical {
    width: 40px;
    border-radius: 30px 30px 0 0;
    background: transparent;
    min-height: 18px;
    max-height: 190px;
  }

  &.current {
    .progress-bar {
      background: $r2;

      #business-block & {
        background: $v370;
      }
    }
  }

  .progress-bar {
    background: $r1;

    #business-block & {
      background: $v1;
    }
  }

  &.progress-bar-vertical {
    .progress-bar {
      background: $r1;
      background: #6D4694;
      width: 100%;
      border-radius: 30px 30px 0 0;
      margin: 0 auto;
    }

    &.active {
      .progress-bar {
        background: #C5A5D6;
      }
    }

  }
}

#lifestyle-block {
  .progress-bar-vertical {
    .progress-bar {
      background: $r1;



    }

    &.active {
      .progress-bar {
        background: $r2;
      }
    }

  }
}

//}



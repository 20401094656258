.dropdown {

  .ui-icon-24 {
    border-radius: 50%;
    margin-right: 0.3rem;
    flex-shrink: 0;
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
    overflow: hidden;
    height: 1.3rem;
    width: 1.3rem;
  }

  .dropdown-toggle {
    @extend .btn-sm;
    font-weight: medium;
    letter-spacing: 0.5px;
    color: currentColor;
    // box-shadow: none!important;
    font-size: 0.75em;
    border-radius: 1rem;
    --smooth-corners: 4;
    -webkit-mask-image: paint(smooth-corners);
    mask-image: paint(smooth-corners);

    color: $brown;
    background-color: white;
    transition-timing-function: cubic-bezier(.16,1,.3,1);
    transition-duration: .2s;
    transition-property: scale, background-color, color;

    svg {
      display: none;
    }

    &:focus {
      box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    }

    &:hover, &[aria-expanded="true"] {
      color: $brown;
      background-color: rgba(white, 1);
      scale: 1.05;
    }
  }

  &.show {
    .dropdown-toggle {
      color: $brown;
      background-color: rgba(white, 1);
    }
  }

  .dropdown-menu {
    margin-top: 3rem;
    color: $brown;
    background-color: white;
    border-radius: 1.5rem;
    --smooth-corners: 4;
    -webkit-mask-image: paint(smooth-corners);
    mask-image: paint(smooth-corners);
    @extend .animate__animated;
    @extend .animate__fadeInDown;
    @extend .animate__faster;

    &:hover {
      .dropdown-item {
        opacity: .5;
      }
    }

    .dropdown-item {
      transition: opacity .15s ease;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: -0.2px;
      outline: none!important;
      color: currentColor;
      padding-left: .25rem;
      padding-right: .25rem;

      &:hover {
        opacity: 1;
      }
    }

    &.show {
      @extend .animate__animated;
      @extend .animate__fadeInDown;
      @extend .animate__faster;
    }
  }
}

.modal.fade .modal-dialog {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  transition: -webkit-transform .6s cubic-bezier(.23, 1, .32, 1);
  transition: transform .6s cubic-bezier(.23, 1, .32, 1);
  transition: transform .6s cubic-bezier(.23, 1, .32, 1), -webkit-transform .6s cubic-bezier(.23, 1, .32, 1);
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal-dialog, .modal {

  .sdg, &.sdg{
    &.sdg--3 {
      .tab-content, .nav-link.active, .box {
        background: hsl(108, 49%, 90%);
      }

      .sectors__item .content, .drivers__item {
        background: hsl(108, 48%, 42%);
      }

      .sectors__item .content .overlay-fill {
        background: hsl(108, 48%, 32%);
      }
    }

    &.sdg--6 {
      .tab-content, .nav-link.active, .box {
        background: hsl(192, 76%, 90%);
      }

      .sectors__item .content, .drivers__item {
        background: hsl(192, 76%, 52%);
      }

      .sectors__item .content .overlay-fill {
        background: hsl(192, 76%, 42%);
      }
    }

    &.sdg--7 {
      .tab-content, .nav-link.active, .box {
        background: hsl(47, 96%, 90%);
      }

      .sectors__item .content, .drivers__item {
        background: hsl(46, 98%, 52%);
      }

      .sectors__item .content .overlay-fill {
        background: hsl(46, 98%, 42%);
      }

    }

    &.sdg--11 {
      .tab-content, .nav-link.active, .box {
        background: hsl(33, 100%, 90%);
      }

      .sectors__item .content, .drivers__item {
        background: hsl(33, 98%, 57%);
      }

      .sectors__item .content .overlay-fill {
        background: hsl(33, 98%, 47%);
      }

    }

    &.sdg--13 {
      .tab-content, .nav-link.active, .box {
        background: hsl(124, 33%, 90%);
      }

      .sectors__item .content, .drivers__item {
        background: hsl(125, 33%, 37%);
      }

      .sectors__item .content .overlay-fill {
        background: hsl(125, 33%, 27%);
      }

    }

    &.sdg--14 {
      .tab-content, .nav-link.active, .box {
        background: hsl(199, 92%, 90%);
      }

      .sectors__item .content, .drivers__item {
        background: hsl(199, 91%, 45%);
      }

      .sectors__item .content .overlay-fill {
        background: hsl(199, 91%, 35%);
      }

    }

    &.sdg--15 {
      .tab-content, .nav-link.active, .box {
        background: hsl(102, 65%, 90%);
      }

      .sectors__item .content, .drivers__item {
        background: hsl(103, 63%, 46%);
      }

      .sectors__item .content .overlay-fill {
        background: hsl(103, 63%, 36%);
      }

    }

    .tab-content {
      & > .tab-pane {
        position: relative;
      }
    }
  }

  .box {
    padding: 24px;
    border-radius: 32px;

    @include large {
      padding: 40px;
    }
  }


  .details {
    margin: 0 auto;

    @include xlarge {
      max-width: 1200px;
    }

    h2 {
      @include large {
        font-size: 72px;
      }
    }

    h3 {
      font-size: 24px;
      
      @include large {
        font-size: 28px;
      }
    }

    p:not(.lead) {
      font-size: 16px;
    }

    .lead {
      font-style: italic;
    }

    .drivers__item {
      display: inline-block;
      border-radius: 16px 16px 16px 0px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      padding: 12px;
      color: white;

      svg {
        display: none;
      }

      &.drivers__item--tooltip {
        cursor: default;

        svg {
          display: inline-block;
          margin-left: 4px;
          fill: currentColor;
        }
      }
    }
  }

  .overlay-background {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    transition: 0.2s;
  }

  .grid__item {
    height: auto !important;
    width: 120px;
    position: relative;
    transition: 0.2s;
    cursor: pointer;
    opacity: 0.7;
    padding: 8px;

    h3 {
      display: none
    }

    .overlay {
      display: none !important;
    }

    img {
      position: relative;
      left: 0;
      max-width: 100%;
    }

    &.active {
      width: 150px;
      opacity: 1;
    }
  }
}

.modal--sdg {
  .swiper-controls, h2, .lead, .box, .nav-tabs, .tab-content {
    opacity: 0;
    transform: translateY(30px);
  }
}

.modal-open {
  .modal--sdg {
    .swiper-controls, h2, .lead, .box, .nav-tabs, .tab-content {
      opacity: 1;
      transition: 0.4s;
      transition-delay: 0.3s;
      transform: translateY(0px);
    }

    .swiper-controls {
      transition-delay: 0.3s;
    }

    h2 {
      transition-delay: 0.33s;
    }

    .lead {
      transition-delay: 0.36s;
    }

    .box {
      transition-delay: 0.39s;
    }

    .nav-tabs, .tab-content {
      transition-delay: 0.42s;
    }
  }
}



.continent-small-cards, .sector-small-cards {
    img {
      border: 2px solid white;
      padding: 0;
    }
}

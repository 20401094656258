span[data-id="footer"], span[data-id="take_action_title"] {
  display: none !important;
}

.in-viewport {

    &.block--impact {

     ~ .scrollspy--holder {
      .bodymovinanim {
        opacity: 1!important;
        transform: translate(0,0);
      }
    }
  }
}

.scrollspy--holder {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;

  display: none;

  @include large {
    display: block;
  }

  // color: white;
  // mix-blend-mode: difference;

  .bodymovinanim {
    position: absolute;
    bottom: 32px;
    right: 16px;
    opacity: 0;
    transition: all .25s ease-in;
    transform: translate(0,8px);
    display: none;

    @include large {
      display: block;
      bottom: 48px;
      right: 32px;
    }

    svg {
      width: 32px!important;
      height: 32px!important;
      opacity: .6;

      path {
        stroke: var(--scrollspy);
      }
    }
  }

  .scrollspy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: -24px;
    right: 0;
    bottom: 0;
    z-index: 10;
    opacity: 1;
    width: 56px;
    transform: translateX(0);
    transition: all .6s cubic-bezier(0.680, -0.550, 0.265, 1.550); /* easeInOutBack */
    transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550); /* easeInOutBack */

    @include large {
      width: 96px;
    }

    &.disabled {
      opacity: 0;
      pointer-events: none;
      transform: translateX(100%);
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      opacity: 0.2;
      border-radius: 50%;
      text-align: center;
      transition: all .25s ease-in-out;
      line-height: 24px;

      &:after {
        content: "•";
        font-size: 24px;
        display: inline-block;
      }

      &.active {
        opacity: 1;
      }
    }
  }
}

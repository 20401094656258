form {
  label {
    // margin-bottom: 12px;
    font-weight: 500;
  }

  .form-check {
    .form-check-label {
      font-weight: 400;
    }
  }
  

  .submit {
    transition: transform .1s ease-in-out;
    &:hover {
      transform: scale(1.1);
      // filter: invert(1);

      // svg {
      //   filter: invert(1);
      // }
    }

    &:active {
      transform: scale(.95);
    }

  }
}


select.form-select {
  cursor: pointer;

  &[disabled] {
    cursor: default;
  }
}

.iconbutton {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    background: none;
    border: 0;
    padding: 0 2rem;
    position: fixed;
    bottom: 24px;
    right: 0;
    z-index: 10;

    .scroll {
      width: 2rem;
      height: 3.25rem;
      border-radius: 1rem;
      border: 2px solid white;
  }

  .scroll__wheel {
      width: 2px;
      height: 1rem;
      background: white;
      border-radius: 0.1rem;
      margin: 0.25rem auto 0;
  }
}

.form-select {
  white-space: nowrap;
}

.form-select, .form-control {
  // transition: background-color .15s ease;
  // &:hover, &:active, &:focus {
  //   background-color: #eee;
  // }
}

.form-control {
  p {
    font-weight: 400;
  }
}

.form-floating {

  & > {
    label {
      font-weight: 400;
    }

    .form-control {
      color: currentColor;
      background-color: rgba(255,255,255,1);
      // background-color: $s2;

      &:focus {
        background-color: rgba(255,255,255,1);

        // border-color: $s2;
      }
    }
    
  }
}


.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label {
  // font-weight: 500;
}
a {
  color: currentColor;
  // text-decoration: underline var(--medium) 2px;
  // -webkit-text-decoration-line:  underline;
  // -webkit-text-decoration-color: var(--medium);
  // -webkit-text-decoration-style: solid;
  // -webkit-text-decoration-thickness: 2px;

  &:hover {
    color: currentColor;
    text-decoration: none;
  }
}

.icn-link {

  &:before {
    content: "";
    margin-right: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 20px;
    width: 20px;
  }
  
  &.ios:before {
    background-image: url('../../../public/media/app_store.svg');
    background-repeat: no-repeat;
  }
  
  &.android:before {
    background-image: url('../../../public/media/google_play.svg');
    background-repeat: no-repeat;
  }

}

